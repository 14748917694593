.manuscript-container-list {
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin-bottom: 16px;
}

.manuscript-container {
  display: flex;
  width: 100%;
  gap: 0px;
  height: auto;
  max-height: 560px;
  min-height: 195px;
}

.reviewers-report-status {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: 4px;
}

.author-tag .ant-tag {
  text-align: center;
  width: 18px;
  height: 16px;
}

.custom-id-status-row {
  align-items: center;
}

.reviewer-report-cell {
  align-items: center;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.custom-id-cell {
  display: flex;
  flex-wrap: wrap;
}

.custom-id-value {
  min-width: 62px;
  height: auto;
}

.manuscript-card {
  width: auto;
  flex-grow: 1;
  border: none;
  height: auto;
  max-height: 560px;
  min-height: 195px;

  gap: 0.25rem;
  min-width: 280px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.manuscript-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 16px;
  background-color: #ffffff;
}

.manuscript-subcontent {
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.manuscript-title {
  padding-top: 0px;
}

.manuscript-title-no-click {
  padding-top: 0px;
}

.manuscript-card-article-type-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.manuscript-footer {
  padding: 0 16px;
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 44px;
  background-color: #f5f5f5;
}

.author-cell {
  width: 100%;
}

.submitted-date {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

@media (max-width: 864px) {
  .manuscript-footer {
    height: auto;
    align-items: normal;
    max-height: none;
    flex-flow: column;
    line-height: 36px;
  }
}

@media (min-width: 1000px) {
  .manuscript-card {
    gap: 0rem;
  }
}

@media (min-width: 1150px) {
  .manuscript-card-article-type-section {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.manuscript-card-article-type {
  min-width: 220px;
}

.manuscript-card-academic-editor {
  min-width: 265px;
}
