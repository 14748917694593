@import '@hindawi/phenom-ui/dist/styles.css';

.ant-dropdown-menu {
  margin-top: 8px;
}

/*
 * **************************************
 * Make the reviewer report survey behave
 * **************************************
 */

#reviewerEmbeddedSurvey .QSIUserDefinedHTML {
  border: 1px solid var(--color-neutral-grey-30);
  border-radius: 4px;
}

#reviewerEmbeddedSurvey .QSIUserDefinedHTML,
#reviewerEmbeddedSurvey .QSIUserDefinedHTML > div,
#reviewerEmbeddedSurvey .QSIUserDefinedHTML > div > div,
#reviewerEmbeddedSurvey .QSIUserDefinedHTML > div > div > div {
  width: auto !important;
  height: auto !important;
  max-width: 800px !important;
}

#reviewerEmbeddedSurvey .QSIUserDefinedHTML > div,
#reviewerEmbeddedSurvey .QSIUserDefinedHTML > div > div {
  position: static !important;
}

#reviewerEmbeddedSurvey .QSIUserDefinedHTML > div > div > div {
  height: 500px !important;
}

/*
 * ***************************************
 */
